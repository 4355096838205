/**
 * Breadcrumb
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { Link } from "gatsby"
import React from "react"

const Breadcrumb = ({ datas }) => {
  return (
    <nav className="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
      <ul>
        <li>
          <Link to="/">
            <span>ホーム</span>
          </Link>
        </li>
        {datas.map(data => {
          return (
            <>
              {data?.link ? (
                <li>
                  <Link to={data.link}>
                    <span>{data.name}</span>
                  </Link>
                </li>
              ) : (
                <li className="is-active">
                  <span>{data.name}</span>
                </li>
              )}
            </>
          )
        })}
      </ul>
    </nav>
  )
}
// Breadcrumb.propTypes = {
//   datas: PropTypes.arrayOf(
//     PropTypes.shape({
//       link: PropTypes.string,
//       name: PropTypes.string,
//     })
//   ),
// }

export default Breadcrumb
